import Vue from "vue";
import Router from "vue-router";
import appConfig from "../../src/app.config";
import { api } from "@/state/services";
import { account } from "@/state/store/auth.module";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("./views/home/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/login",
      component: () => import("./views/login/index"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login/token"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/login/recuperar-senha",
      component: () => import("./views/login/senha-recuperar"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/login/alterar-senha",
      component: () => import("./views/login/senha-alterar"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/login/senha-transacao",
      component: () => import("./views/login/senha-transacao/index.vue"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/configuracoes",
      component: () => import("./views/configuracoes/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/configuracoes/alterar-senha",
      component: () => import("./views/configuracoes/alterar-senha"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/extrato",
      component: () => import("./views/extrato/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/transferencias",
      component: () => import("./views/transferencias/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/transferencias/receber",
      component: () => import("./views/transferencias/receber/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },  
    },
    {
      path: "/transferencias/enviar",
      component: () => import("./views/transferencias/enviar/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },  
    },
    {
      path: "/transferencias/enviar/ted",
      component: () => import("./views/transferencias/enviar/ted"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },  
    },
    {
      path: "/transferencias/enviar/pix",
      component: () => import("./views/transferencias/enviar/pix"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },  
    },
    {
      path: "/pix",
      component: () => import("./views/pix/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },  
    },
    {
      path: "/pix/criar",
      component: () => import("./views/pix/criar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },  
    },
    {
      path: "/pagamentos",
      component: () => import("./views/pagamentos/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/pagamentos/boleto",
      component: () => import("./views/pagamentos/boleto/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/pagamentos/pix",
      component: () => import("./views/pagamentos/pix/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/pagamentos/pix/qrcode",
      component: () => import("./views/pagamentos/pix/qrcode"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos",
      component: () => import("./views/favorecidos/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/cadastrar",
      component: () => import("./views/favorecidos/cadastrar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/importar",
      component: () => import("./views/favorecidos/importar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/importar/:id",
      component: () => import("./views/favorecidos/importar_arquivo"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/favorecidos/:id",
      component: () => import("./views/favorecidos/editar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/folha-de-pagamento",
      component: () => import("./views/folha-de-pagamento/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/folha-de-pagamento/criar",
      component: () => import("./views/folha-de-pagamento/criar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/folha-de-pagamento/importar",
      component: () => import("./views/folha-de-pagamento/importar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/folha-de-pagamento/importar/:id",
      component: () => import("./views/folha-de-pagamento/importar_arquivo"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes",
      component: () => import("./views/clientes/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/cadastrar",
      component: () => import("./views/clientes/cadastrar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/importar",
      component: () => import("./views/clientes/importar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/importar/:id",
      component: () => import("./views/clientes/importar_arquivo"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/:id",
      component: () => import("./views/clientes/editar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/boleto/:boletoId",
      component: () => import("./views/boleto/visualizar-comprovante.vue"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/carteiras",
      component: () => import("./views/carteiras/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/carteiras/criar",
      component: () => import("./views/carteiras/criar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas",
      component: () => import("./views/cobrancas/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas/criar",
      component: () => import("./views/cobrancas/criar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas/boleto/avulso",
      component: () => import("./views/cobrancas/boleto/avulso"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas/boleto/lote",
      component: () => import("./views/cobrancas/boleto/lote"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/cobrancas/importar",
      component: () => import("./views/cobrancas/importar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/relatorios",
      component: () => import("./views/relatorios/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (!response.data && !response.data.status == "success") {
          return next("/login");
        }

        account.state.user = response.data.user;
        localStorage.setItem("user", JSON.stringify(response.data.user));
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }

  document.title = to.meta.title;
  next();
});

export default router;
